import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout.js";

const Page = () => {
  return (
    <Layout>
      <Head title="Goran" />
      <h3>Goran</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Bosnia
      </h4>
      <p>
        <OutboundLink href="https://ello.co/gorango">Ello</OutboundLink>
      </p>

      <p>
        Having lived the horrors of war in Bosnia from 1990-1995, a part of
        Goran’s work rests on memories and feelings from what he experienced
        back then.
      </p>

      <p>
        The duality of the connection between life and death is often expressed
        in his work.
      </p>

      <p>
        For Goran, the way to live with it is to express his feelings and
        memories through art.
      </p>

      <p>
        The quest for beauty based on a compliance with established rules,
        techniques and themes could not translate the ardor of his creations.
      </p>

      <p>
        His work often features both living and ghostly figures with a movement
        of abstract that the artist works in by applying different techniques
        and materials.
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItem: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ marginBottom: 15 }}
          src="https://assets1.ello.co/uploads/asset/attachment/15902632/ello-optimized-2c63c6a7.jpg"
          alt="Goran art"
        />
        <img
          style={{ marginBottom: 15 }}
          src="https://assets1.ello.co/uploads/asset/attachment/13693455/ello-optimized-5cf84128.jpg"
          alt="Goran art"
        />
        <img
          style={{ marginBottom: 15 }}
          src="https://assets0.ello.co/uploads/asset/attachment/13711090/ello-optimized-b1742bb0.jpg"
          alt="Goran art"
        />
        <img
          style={{ marginBottom: 15 }}
          src="https://assets1.ello.co/uploads/asset/attachment/13394842/ello-optimized-43de7c01.jpg"
          alt="Goran art"
        />
        <img
          style={{ marginBottom: 15 }}
          src="https://assets2.ello.co/uploads/asset/attachment/14185405/ello-optimized-b70fcfe6.jpg"
          alt="Goran art"
        />
        <p>Year IV December 2021Kamiwaza / Stained VIII</p>

        <br />
        <br />
        <br />
        <h3>
          <Link to="/">artnow.world</Link>
        </h3>
        <br />
      </div>
    </Layout>
  );
};

export default Page;
